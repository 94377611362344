import * as React from 'react'
import Layout from '../components/layout'
import {
  pageBody,
  legalPage,
} from '../components/layout.module.scss'
import Seo from '../components/seo'

export default function TermsPage() {
  return (
    <Layout pageTitle="Terms">
    <Seo 
      title="Terms & Conditions"
      description="The terms and conditions for using the BRADIENT Agency website." 
    />
      <div className={pageBody}>
        <div className={legalPage}>
          <p>These Terms of Use (the &ldquo;Terms of Use&rdquo;) govern your use of all Websites operated by BRADIENT LLC. (&ldquo;BRADIENT&rdquo;), including, without limitation, the Web site currently located bradient.com (collectively, the &ldquo;Site&rdquo;), provided for your informational, educational and entertainment purposes. Please read these Terms of Use carefully. These Terms of Use constitute a binding agreement between you and BRADIENT regarding your use of the Site.</p>
          <p>By using the Site, you agree to be bound by these Terms of Use and the BRADIENT Privacy Policy. If you do not agree to any provision of these agreements, you may not use the Site.</p>
          <p>(1) TERMS OF USE CHANGES</p>
          <p>BRADIENT may, in its sole and absolute discretion, change these Terms of Use from time to time. BRADIENT will post a copy of these Terms of Use as changed on the Site at <a data-fr-linked="true" href="/terms-conditions/">https://bradient.com/terms-conditions/</a> Your continued use of the Site constitutes your agreement to abide by these Terms of Use as changed. If you object to any such changes, your sole recourse shall be to cease using the Site.</p>
          <p>(2) OWNERSHIP</p>
          <p>The content on the Site, including, without limitation, text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the like (collectively, &ldquo;BRADIENT. Content&rdquo;), and the BRADIENT word marks and design marks, as well as certain other of the names, logos and materials displayed on the Site that constitute trademarks (collectively, &ldquo;Marks&rdquo;), are owned by or licensed to BRADIENT and are subject to copyright, trademark and other intellectual property rights under U.S. and international laws.</p>
          <p>(3) PRIVACY POLICY</p>
          <p>You agree that BRADIENT may use any information BRADIENT obtains about you in accordance with the provisions of the BRADIENT Privacy Policy</p>
          <p><a data-fr-linked="true" href="/privacy-policy/">https://www.bradient.com/privacy-policy/</a></p>
          <p>(4) LINKS TO OTHER WEBSITES</p>
          <p>The Site may contain links to third party Web sites or Internet resources that are not owned or controlled by BRADIENT. BRADIENT&rsquo;s provision of a link to any other Web site or Internet resource is for your convenience only and does not signify BRADIENT&rsquo;s endorsement of such other Web site or resource or its contents. BRADIENT SHALL NOT BE RESPONSIBLE FOR THE AVAILABILITY OF ANY THIRD PARTY WEB SITES OR RESOURCES, AND BRADIENT DOES NOT WARRANT, ENDORSE GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY CONTENT, INFORMATION, SOFTWARE, MATERIALS OR PRACTICES OF ANY SUCH THIRD PARTY WEB SITES OR RESOURCES, INCLUDING, WITHOUT LIMITATION, ANY PRODUCTS OR SERVICES ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES OR THROUGH ANY LINKED WEB SITES OR FEATURED IN ANY ADVERTISING.</p>
          <p>(5) DISCLAIMER</p>
          <p>YOU EXPRESSLY AGREE THAT USE OF THE site IS AT YOUR SOLE RISK. THE SITE ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, BRADIENT EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, ACCURACY AND NON-INFRINGEMENT), WITH RESPECT TO THE site. without limiting the foregoing and TO THE MAXIMUM EXTENT PERMITTED by LAW, BRADIENT ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY:</p>
          <p>ANY INTERRUPTION OF TRANSMISSION TO OR FROM THE SITE;</p>
          <p>ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY;</p>
          <p>AND FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, E-MAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE ON OR THROUGH THE SITE.</p>
          <p>(6) LIMITATION OF LIABILITY</p>
          <p>IN NO EVENT WILL BRADIENT BE LIABLE UNDER ANY THEORY OF LIABILITY (WHETHER IN CONTRACT, TORT, STATUTORY, OR OTHERWISE) FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF IT WAS ADVISED OF, KNEW OF, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES).</p>
          <p>(7) EXCLUSIONS AND LIMITATIONS</p>
          <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent BRADIENT may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of BRADIENT&rsquo;s liability shall be the minimum permitted under such applicable law</p>
          <p>(8) INDEMNITY</p>
          <p>You agree to indemnify, defend, and hold harmless BRADIENT and its affiliates and any of their officers, employees, directors, shareholders, agents, partners, licensors, successors and assigns (the &ldquo;BRADIENT Parties&rdquo;) from and against any and all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys&rsquo; fees and costs) that such parties may incur as a result of or arising from (a) any information you provide to BRADIENT; (b) your use of the Site; or (c) your violation of these Terms of Use. BRADIENT reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with BRADIENT in asserting any available defenses.</p>
          <p>(9) FORCE MAJEURE</p>
          <p>Without limiting the foregoing, under no circumstances will BRADIENT be held liable for any delay or failure in performance due in whole in or in part to any acts of nature, forces, or causes beyond its reasonable control, including, without limitation, Internet failures, computer equipment failures, software bugs, telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, earthquakes, storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or fluctuations in heat, light, or air conditioning.</p>
          <p>(10) TERMINATION</p>
          <p>BRADIENT may terminate or suspend access to the Site immediately, without prior notice or liability, if you breach any provision of these Terms of Use. In the event of any such termination, the indemnification provisions set forth above shall survive.</p>
          <p>(11) ARBITRATION</p>
          <p>You and BRADIENT agree that we will resolve any disputes between us through binding and final arbitration instead of through court proceedings. You and BRADIENT hereby waive any right to a jury trial of any Claim. All controversies, claims, counterclaims, or other disputes arising between you and BRADIENT relating to the Site or these Terms of Use (each a &ldquo;Claim&rdquo;) shall be submitted for binding arbitration in accordance with the Rules of the American Arbitration Association (&ldquo;AAA Rules&rdquo;). The arbitration will be heard and determined by a single arbitrator in Los Angeles, California. The arbitrator&rsquo;s decision in any such arbitration will be final and binding upon the parties and may be enforced in any court of competent jurisdiction. The parties agree that the arbitration will be kept confidential and that the existence of the proceeding and any element of it (including, without limitation, any pleadings, briefs, documents, or other evidence submitted or exchanged and any testimony or other oral submissions and awards) will not be disclosed beyond the arbitration proceedings, except as may lawfully be required in judicial proceedings relating to the arbitration or by applicable disclosure rules and regulations of securities regulatory authorities or other governmental agencies.</p>
          <p>You may not act as a class representative or private attorney general, nor participate as a member of a class of claimants, with respect to any Claim. Claims may not be arbitrated on a class or representative basis. The arbitrator can decide only your and/or BRADIENT&rsquo;s individual Claims. The arbitrator may not consolidate or join the claims of other persons or parties who may be similarly situated.</p>
          <p>THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES, AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF. OTHER RIGHTS THAT YOU</p>
          <p>OR BRADIENT WOULD HAVE IN COURT ALSO MAY NOT BE AVAILABLE IN ARBITRATION.</p>
          <p>(12) GENERAL PROVISIONS</p>
          <p>These Terms of Use shall be governed by and construed in accordance with the laws of the State of California, without giving effect to any conflict of laws rules or provisions. If any provision of these Terms of Use shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions. The failure of BRADIENT to enforce any right or provision of these Terms of Use will not be deemed a waiver of such right or provision. These Terms of Use, the BRADIENT Privacy Policy <a data-fr-linked="true" href="/privacy-policy/">https://bradient.com/privacy-policy/</a> together with and any other policies, rules or guidelines posted in connection with the Site, constitute the entire and exclusive agreement between BRADIENT and you regarding the Site.</p>
          <p>Effective Date: December 25, 2022</p>
        </div>
      </div>
    </Layout>
  )
}